import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface ComponentProps {
  title?: string;
  description?: string;
  pageUrl?: string;
  thumbnailUrl?: string;
  language?: string;
  locale?: string;
  facebookAppId?: string;
  imageAlt?: string;
}

const MetaData: React.FunctionComponent<ComponentProps> = ({
  title,
  description,
  pageUrl,
  thumbnailUrl,
  language,
  locale,
  facebookAppId,
  imageAlt
}) => {
  const { site } = useStaticQuery<any>(
    graphql`
      query metaData{
        site {
          siteMetadata {
            title
            description
            siteUrl
            logoUrl
            language
            locale
            facebookAppId
            twitterHandle
          }
        }
      }
    `,
  );

  const titleString = title || site.siteMetadata.title;
  const descriptionString = description || site.siteMetadata.description;

  let siteUrlString = '';
  if (pageUrl != '' && pageUrl) {
    siteUrlString = (site.siteMetadata.siteUrl + pageUrl)
  } else {
    siteUrlString = site.siteMetadata.siteUrl;
  }

  const logoImageUrl = thumbnailUrl || site.siteMetadata.logoUrl;
  const imageAltString = imageAlt || site.siteMetadata.logoUrl;
  const languageString = language || site.siteMetadata.language;
  const localeString = locale || site.siteMetadata.locale;
  const appIdString = facebookAppId || site.siteMetadata.facebookAppId;
  const twitterHandle = site.siteMetadata.twitterHandle;

  return (
    <Helmet
      title={`${titleString} | Vegetar`}
      htmlAttributes={{ lang: languageString }}
      link={[
      {rel: 'canonical', href: siteUrlString },
      {rel:'stylesheet', href:"https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"},
      {rel:'stylesheet', href:"https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"}]}
      meta={[
        {
          name: `description`,
          content: descriptionString,
        },
        {
          name: `twitter:site`,
          content: twitterHandle,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: titleString,
        },
        {
          name: `twitter:description`,
          content: descriptionString,
        },
        {
          name: `twitter:image`,
          content: logoImageUrl,
        },
        {
          property: `og:title`,
          content: titleString,
        },
        {
          property: `og:description`,
          content: descriptionString,
        },
        {
          property: `og:url`,
          content: siteUrlString,
        },
        {
          property: `og:image`,
          content: logoImageUrl,
        },
        {
          property: `og:image:alt`,
          content: imageAltString,
        },
        {
          property: `og:site_name`,
          content: titleString,
        },
        {
          property: `og:locale`,
          content: localeString,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `fb:app_id`,
          content: appIdString,
        },
      ]}
    />
  );
};

export default MetaData;
