import React from 'react';

interface ComponentProps {
  children: any;
  className?: string;
}

const WideContainer: React.FunctionComponent<ComponentProps> = ({
  children,
  className,
}) => (
  <div className={`container mx-auto px-4 ${className}`}>
    <div className="lg:w-12/12 mx-auto">{children}</div>
  </div>
);

WideContainer.defaultProps = {
  className: ``,
} as Partial<ComponentProps>;

export default WideContainer;
