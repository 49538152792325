import * as React from 'react';
import { Link } from 'gatsby';
import { useState } from 'react';

interface ComponentProps {
    children: string,
    to: string,
}

const FooterLink: React.FunctionComponent<ComponentProps> = ({ children, to }) => {
    const [hover, setHover] = useState<boolean>(false);

    const hoverEffect = (): void => setHover(!hover);

    return (
        <Link to={to} title={children}>
            <p className="text-gray-900 text-sm mt-1 mb-3" onMouseOver={hoverEffect} onMouseLeave={hoverEffect}>
                {children}
            </p>
        </Link>
    );
}

export default FooterLink;