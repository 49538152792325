import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import FooterLink from './FooterLink';
import SocialComponent from './SocialComponent';
import Routes from '../../constants/Routes';
import Colors from '../../constants/Colors';
import Variables from '../../constants/Variables';
import WideContainer from '../layout/WideContainer';
import SvgWaves from './SvgWaves';

const Footer: React.FunctionComponent<{}> = () => {
  const { site } = useStaticQuery<any>(
    graphql`
      query staticTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `,
  );

  const { title } = site.siteMetadata;

  return (
      <div>
        <SvgWaves />
        <div className="footer light-theme-background pb-10">
          <WideContainer>
            <div className="grid lg:grid-cols-5 sm:grid-cols-1">
              <div>
                <h3 className="headline mt-2">vegetar</h3>
                <p className="text-sm pr-4">Vegetariske opskrifter og retter. Søg blandt alle de forskellige lækre opskrifter.</p>
              </div>
              <div>
                <h4 className="text-base mb-4">
                  Sider
                </h4>
                <FooterLink to={Routes.HOME}>Forside</FooterLink>
                <FooterLink to={Routes.KATEGORIER}>Kategorier</FooterLink>
                <FooterLink to={Routes.OPSKRIFTER}>Søg mellem opskrifter</FooterLink>
                <FooterLink to={Routes.AUTHORS}>Vores kokke</FooterLink>
              </div>
              <div>
                <h4 className="text-base mb-4">
                  Opskrifter
                </h4>
                <FooterLink to={Routes.AUBERGINE_ROLLS}>Aubergineruller med feta</FooterLink>
                <FooterLink to={Routes.ISRAEL_COUSCOUS}>Israelsk couscous salat</FooterLink>
                <FooterLink to={Routes.ASIAN_PASTA}>Asiatisk pasta med forårsløg</FooterLink>
                <FooterLink to={Routes.CALZONE_RICOTTA}>Calzone med ricotta og spinat</FooterLink>
              </div>
              <div>
                <h4 className="text-base mb-4">
                  Guides
                </h4>
                <FooterLink to={Routes.VEGETAR_VS_VEGANER}>Vegetar vs. Veganer</FooterLink>
                <FooterLink to={Routes.HVOR_MANGE_VEGETARER_I_DANMARK}>Vegetarer i Danmark</FooterLink>
                <FooterLink to={Routes.HOME}>Gode grunde til at blive vegetar</FooterLink>
                <FooterLink to={Routes.HOME}>Protein som vegetar</FooterLink>
              </div>
              <div>
                <h4 className="text-base mb-4">
                  Om Siden
                </h4>
                <FooterLink to={Routes.HOME}>Kontakt</FooterLink>
                <FooterLink to={Routes.HOME}>Spørgsmål</FooterLink>
              </div>
            </div>
            <hr className="mt-16 mb-8" />
            <div className="grid lg:grid-cols-2 sm:grid-cols-1">
              <div>
                <SocialComponent />
              </div>
              <div>
                <div className="inline-block text-center px-1 py-1 float-right">
                  <Link to={Routes.HOME} title="Privatpolitik">
                    <span style={legalLink}>Privatpolitik</span> 
                  </Link>
                </div>
                <div className="inline-block text-center px-1 py-1 float-right">
                  <span className="copyright" style={copyright}>©{new Date().getFullYear()}, {title}</span> 
                </div>
              </div>
            </div>
          </WideContainer>
        </div>
      </div>
  );
};

const legalLink = {
  color: Colors.almostBlack,
  fontFamily: Variables.fontFamilyText,
  fontSize: 14,
  lineHeight: 2,
  paddingLeft: 20,
  paddingRight: 20
};

const copyright = {
  color: Colors.almostBlack,
  fontFamily: Variables.fontFamilyText,
  fontSize: 14,
  lineHeight: 2,
  opacity: 1
};

export default Footer;