import * as React from 'react';

const SvgWaves: React.FunctionComponent = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path 
                fill="#FFF8EE" 
                fillOpacity="1" 
                d="M0,192L80,192C160,192,320,192,480,208C640,224,800,256,960,256C1120,256,1280,224,1360,208L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z">
            </path>
        </svg>
    );
}

export default SvgWaves;