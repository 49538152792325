export default {

    // Main pages
    HOME: '/',
    OPSKRIFTER: '/opskrifter/',
    KATEGORIER: '/kategorier/',
    AUTHORS: '/authors/',
    PRIVATPOLITIK: '/privatpolitik/',
    TAG: '/tag/',
    KONTAKT: '/kontakt/',
    TAK: '/tak/',

    // CATEGORIES
    HOVEDRET: '/kategori/hovedret/',
    FORRET: '/kategori/forret/',
    SALAT: '/kategori/salat/',
    DESSERT: '/kategori/dessert/',
    TILBEHØR: '/kategori/tilbehoer/',
    DRINKS: '/kategori/drink/',

    // INGREDIENTS
    AEBLE: '/tag/aeble/',
    PAERE: '/tag/paere/',
    MAJS: '/tag/majs/',
    ROEDBEDE: '/tag/roedbede/',
    GULEROD: '/tag/gulerod/',
    CITRON: '/tag/citron/',
    TOMAT: '/tag/tomat/',
    KARTOFFEL: '/tag/kartoffel/',
    KAAL: '/tag/kaal/',
    NOEDDER: '/tag/noedder/',
    AEG: '/tag/aeg/',
    SVAMPE: '/tag/svampe/',
    AVOCADO: '/tag/avocado/',

    // RECIPES FOR FOOTER LINKS
    ISRAEL_COUSCOUS: '/opskrift/israelsk-couscous-salat/',
    ASIAN_PASTA: '/opskrift/asiatisk-pasta-med-forarslog-og-ingefaer/',
    AUBERGINE_ROLLS: '/opskrift/aubergineruller-med-feta/',
    CALZONE_RICOTTA: '/opskrift/calzone-med-ricotta-og-spinat/',

    // ARTICLES
    VEGETAR_VS_VEGANER: '/info/vegetar-vs-veganer-vs-pescetar-vs-fleksitar/',
    HVOR_MANGE_VEGETARER_I_DANMARK: '/info/hvor-mange-vegetarer-er-der-i-danmark/',

    RECIPEPREFIX: 'opskrift'
};